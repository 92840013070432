<template>
    <div class="main">
        <div class="top_header">
            <div class="head">
                <img :src="userinfo.avatar" alt="">
            </div>
            <div class="user">
                <div class="nickname">
                    <span v-if="disabled">{{ paramName.nickName }}</span>
                    <input v-else class="nickname" @change="tixing = true" maxlength="10" :disabled="disabled"
                        v-model="paramName.nickName" placeholder="请输入昵称" />
                    <!-- {{ userinfo.nickName }} -->
                    <!-- <div class="user_ico" @click="tixing = true"> -->
                    <div class="user_ico" @click="disabled = !disabled">
                        <img src="../../assets/person/bj.png" alt="">
                    </div>
                </div>
                <div class="renzheng">
                    {{ userinfo.utypeValue }}
                </div>
            </div>
        </div>
        <div class="forms">
            <ul>
                <li>
                    <div class="li_left">
                        <div class="li_ico">
                            <img src="../../assets/person/pwd.png" alt="">
                        </div>
                        <div class="li_title">
                            登录密码
                        </div>
                        <div class="line"></div>
                        <div class="li_tip" v-if="userinfo.pwdIsSet == 1">
                            密码已设置
                        </div>
                        <div class="li_tip" v-if="userinfo.pwdIsSet == 0">
                            密码未设置
                        </div>
                    </div>
                    <div class="li_right">
                        <div class="li_btn" v-if="userinfo.pwdIsSet == 1" @click="changePwd = true">
                            修改密码
                        </div>
                        <div class="li_btn" v-if="userinfo.pwdIsSet == 0" @click="changePwd = true">
                            设置密码
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li_left">
                        <div class="li_ico">
                            <img src="../../assets/person/tel.png" alt="">
                        </div>
                        <div class="li_title">
                            绑定手机
                        </div>
                        <div class="line"></div>
                        <div class="li_tip">
                            您绑定的手机号：<span>{{ userinfo.phone }}</span>
                        </div>
                    </div>
                    <div class="li_right">
                        <div v-if="userinfo.phone != ''" class="li_btn"
                            style="width: 156px;height: 40px;padding:0;text-align:center;line-height: 40px;"
                            @click="changeTel = true">
                            修改手机号
                        </div>
                        <div v-else class="li_btn"
                            style="width: 156px;height: 40px;padding:0;text-align:center;line-height: 40px;"
                            @click="bindTel = true">
                            绑定手机号
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li_left">
                        <div class="li_ico">
                            <img src="../../assets/person/wx.png" alt="">
                        </div>
                        <div class="li_title">
                            绑定微信
                        </div>
                        <div class="line"></div>
                        <div class="li_tip" v-if="userinfo.wxIsBind == 0">
                            微信号未绑定
                        </div>
                        <div class="li_tip" v-if="userinfo.wxIsBind == 1">
                            微信号已绑定
                        </div>
                    </div>
                    <div class="li_right">
                        <div class="li_btn" @click="bindWx">
                            扫码绑定
                        </div>
                    </div>
                </li>
                <li>
                    <div class="li_left">
                        <div class="li_ico">
                            <img src="../../assets/person/code.png" alt="">
                        </div>
                        <div class="li_title">
                            身份认证
                        </div>
                        <div class="line"></div>
                        <div class="li_tip">
                            您当前身份：<span style="color:#333333;font-weight: bold;" class="li_tip">{{ userinfo.utypeValue
                            }}</span>,企业用户可以发布更多信息
                        </div>
                    </div>
                    <div class="li_right">
                        <div style="width: 156px;height: 40px;padding:0;text-align:center;line-height: 40px;"
                            v-if="userinfo.authStatus == 2" class="li_btn" @click="goRenzheng">
                            已认证
                        </div>
                        <div v-else class="li_btn" @click="goRenzheng">
                            立即认证
                        </div>
                        <!-- <router-link class="li_btn" to="/renzheng">立即认证</router-link> -->


                    </div>
                </li>
            </ul>
        </div>
        <div>
            <el-dialog top="160px" title="提醒" :center="true" :visible.sync="tixing" width="700px"
                :before-close="handleClose">
                <div class="form">
                    <div class="tishi_con">
                        修改昵称需要后台审核，请耐心等待哦~
                    </div>
                    <div class="other">
                        <div class="no" @click="changeNo">
                            取消修改
                        </div>
                        <div class="yes" @click="changeYes">
                            确认修改
                        </div>
                    </div>

                </div>

            </el-dialog>

        </div>
        <div>
            <el-dialog top="160px" title="修改密码" :center="true" :visible.sync="changePwd" width="700px"
                :before-close="handleClose">
                <div class="form">
                    <div class="tel"><input v-model="param.phone" type="text" placeholder="请输入手机号"></div>
                    <div class="code">
                        <input v-model="param.code" type="text" placeholder="请输入验证码">
                        <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                            @click="getCode('pwd')">
                            <span v-if="show">发送验证码</span>
                            <span v-else>重新发送({{ count }}s)</span>
                        </button>
                    </div>
                    <div class="tel"><input @blur="newPwd1" v-model="param.newPwd" type="password" placeholder="请输入新密码">
                        <div style="font-size:14px;color:red;padding:0;">密码长度8~16位，其中字母、数字、符号须包含至少两种</div>
                    </div>

                    <div class="tel"><input @blur="newPwd2" v-model="param.confirmNewPwd" type="password"
                            placeholder="请再次输入新密码">
                        <div style="font-size:14px;color:red;padding:0;">密码长度8~16位，其中字母、数字、符号须包含至少两种</div>
                    </div>
                    <div class="other">
                        <div class="no" @click="changePwdNo">
                            取消修改
                        </div>
                        <div class="yes" @click="changePwdYes">
                            确认修改
                        </div>
                    </div>




                </div>

            </el-dialog>

        </div>
        <div>
            <el-dialog top="160px" title="修改绑定手机号" :center="true" :visible.sync="changeTel" width="700px"
                :before-close="handleClose">
                <div class="form" v-if="step == 1">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/changeTel/yzsf1.png" alt="">
                            <img src="../../assets/changeTel/bdsj.png" alt="">
                            <img src="../../assets/changeTel/bdcg.png" alt="">
                        </div>
                    </div>
                    <div class="tel"><input v-model="paramTel.phone" type="text" placeholder="请输入原手机号"></div>
                    <div class="code">
                        <input type="text" v-model="paramTel.code" placeholder="请输入验证码">
                        <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                            @click="getCode('tel')">
                            <span v-if="show">发送验证码</span>
                            <span v-else>重新发送({{ count }}s)</span>
                        </button>
                    </div>
                    <div class="other" style="padding:0 50px;">
                        <div class="btn" @click="nextStep">
                            下一步
                        </div>
                    </div>
                </div>
                <div class="form" v-if="step == 2">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/changeTel/yzsf1.png" alt="">
                            <img src="../../assets/changeTel/bdsj1.png" alt="">
                            <img src="../../assets/changeTel/bdcg.png" alt="">
                        </div>
                    </div>
                    <div class="tel"><input v-model="paramTel.phoneNew" type="text" placeholder="请输入新手机号"></div>
                    <div class="code">
                        <input v-model="paramTel.codeNew" type="text" placeholder="请输入验证码">
                        <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                            @click="getCode('tel1')">
                            <span v-if="show">发送验证码</span>
                            <span v-else>重新发送({{ count }}s)</span>
                        </button>
                    </div>
                    <div class="other" style="justify-content: space-around !important;">
                        <div class="btns" @click="step = 1">
                            上一步
                        </div>
                        <div class="btns" @click="submit">
                            确认绑定
                        </div>
                    </div>
                </div>
                <div class="form" v-if="step == 3">
                    <div class="stepList">
                        <div class="step">
                            <img src="../../assets/changeTel/yzsf1.png" alt="">
                            <img src="../../assets/changeTel/bdsj1.png" alt="">
                            <img src="../../assets/changeTel/bdcg1.png" alt="">
                        </div>
                    </div>
                    <div class="dui" style="text-align: center;">
                        <img style="width: 166px;height: 166px;" src="../../assets/changeTel/dui.png" alt="">
                    </div>
                    <div class="other">
                        <div class="btn" @click="changeTel = false">
                            绑定成功
                        </div>
                    </div>
                </div>

            </el-dialog>

        </div>
        <div>
            <el-dialog top="160px" title="绑定手机号" :center="true" :visible.sync="bindTel" width="700px"
                :before-close="handleClose">
                <div class="form" v-if="stepss == 1">
                    <div class="stepList">
                        <div class="step">
                            <!-- <img src="../../assets/changeTel/yzsf1.png" alt=""> -->
                            <img src="../../assets/changeTel/bdsj1.png" alt="">
                            <img src="../../assets/changeTel/bdcg.png" alt="">
                        </div>
                    </div>
                    <div class="tel"><input v-model="paramBindTel.phoneNew" type="text" placeholder="请输入手机号"></div>
                    <div class="code">
                        <input v-model="paramBindTel.codeNew" type="text" placeholder="请输入验证码">
                        <button v-bind:class="{ grey: isGrey, blue: !isGrey }" v-bind:disabled="dis" type="primary"
                            @click="getCode('tel11')">
                            <span v-if="show">发送验证码</span>
                            <span v-else>重新发送({{ count }}s)</span>
                        </button>
                    </div>
                    <div class="other">
                        <div class="btn" @click="submitss">
                            确认绑定
                        </div>
                    </div>
                </div>
                <div class="form" v-if="stepss == 2">
                    <div class="stepList">
                        <div class="step">
                            <!-- <img src="../../assets/changeTel/yzsf1.png" alt=""> -->
                            <img src="../../assets/changeTel/bdsj1.png" alt="">
                            <img src="../../assets/changeTel/bdcg1.png" alt="">
                        </div>
                    </div>
                    <div class="dui" style="text-align: center;">
                        <img style="width: 166px;height: 166px;" src="../../assets/changeTel/dui.png" alt="">
                    </div>
                    <div class="other">
                        <div class="btn" @click="changeTel = false">
                            绑定成功
                        </div>
                    </div>
                </div>

            </el-dialog>

        </div>
        <div>
            <el-dialog top="160px" title="扫描二维码绑定微信" :center="true" :visible.sync="changeWx" width="600px"
                :before-close="handleClose">
                <div class="form">
                    <div class="ewm_img">
                        <!-- <img src="@/assets/sao.png" alt=""> -->
                        <iframe :src="url" width="70%" height="400px" frameborder="0"></iframe>
                    </div>
                    <!-- <div class="tip">
                        <div class="tip_ico">
                            <img src="@/assets/sao.png" alt="">
                        </div>
                        <div class="tip_con">
                            <div class="every_con">打开微信扫一扫</div>
                        </div>
                    </div> -->
                </div>

            </el-dialog>

        </div>
    </div>
</template>
<script>


export default {
    data() {
        let that = this;
        return {
            disabled: true,
            tixing: false,
            changePwd: false,
            changeTel: false,
            bindTel: false,
            stepss: 1,
            changeWx: false,
            dis: false,
            show: true,
            isGrey: false, //按钮样式
            timer: null, //设置计时器,
            count: "",
            step: 1,
            userinfo: {},
            param: {
                phone: '',
                code: '',
                newPwd: '',
                confirmNewPwd: '',
                updatePhoneType: 2
            },
            paramTel: {
                phone: '',
                code: '',
                phoneNew: '',
                codeNew: ""
            },
            paramBindTel: {
                phone: '',
                code: '',
                phoneNew: '',
                codeNew: ""
            },
            url: '',
            paramName: {
                nickName: '',
            },

        };
    },

    mounted() {

    },
    created() {
        this.getUser()
        this.checkPassword()

    },
    methods: {
        newPwd1() {
            let a = this.checkPassword(this.param.newPwd)
            console.log(a);
            if (!a) {
                this.$message({
                    message: "密码格式不正确",
                    type: 'warning',
                    offset: 100
                });
            }
        },
        newPwd2() {
            let a = this.checkPassword(this.param.confirmNewPwd)
            console.log(a);
            if (!a) {
                this.$message({
                    message: "密码格式不正确",
                    type: 'warning',
                    offset: 100
                });
            }
        },
        checkPassword(a) {
            const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/
            let isValid = reg.test(a)
            // console.log(isValid);
            return isValid
        },
        async goRenzheng() {
            let user = await this.$api.base.getUser()
            if (user.info.authStatus == 2) {
                this.$message({
                    message: "请勿重复认证",
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.$router.push({ path: '/renzheng' });
        },
        bindWx() {
            if (this.userinfo.wxIsBind == 1) {
                this.$message({
                    message: '请勿重复绑定',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            this.getEwm()
            this.changeWx = true
        },
        async getEwm() {
            let phone = ''
            if (this.userinfo.phone) {
                phone = this.userinfo.phone
            }
            let data = await this.$api.base.register(
                {
                    loginType: 2,
                    isWebWxBind: 1
                }
            )
            this.url = data.authorize_url
            console.log(data);
        },
        // 确认修改
        nextStep() {
            let param = this.paramTel
            console.log(param);
            if (param.phone == "") {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.phone != this.userinfo.phone) {
                this.$message({
                    message: '手机号不正确',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.code == "") {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            this.step = 2
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false
            this.timer = null
        },
        async submitss() {
            let param = this.paramBindTel
            if (param.phoneNew == "") {
                this.$message({
                    message: '请输入新手机号',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.codeNew == "") {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let data = await this.$api.base.changeTel(param)
            console.log(data);
            if (data == null) {
                this.stepss = 3
                this.$message({
                    message: "绑定成功",
                    type: 'success',
                    offset: 100
                });
            } else if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'warning',
                    offset: 100
                });
                return
            }
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false
        },
        async submit() {
            let param = this.paramTel
            console.log(param);
            if (param.phoneNew == "") {
                this.$message({
                    message: '请输入新手机号',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.codeNew == "") {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.phoneNew.length != 11 || param.phone.length != 11) {
                this.$message({
                    message: '手机号无效，请输入正确的手机号',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            if (param.phoneNew == param.phone) {
                this.$message({
                    message: '新手机号和原手机号不能相同',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let data = await this.$api.base.changeTel(param)
            console.log(data);
            if (data == null) {
                this.step = 3
            } else {
                if (data.code == -9999) {
                    this.$message({
                        message: data.msg,
                        type: 'error',
                        offset: 100
                    });
                }
            }
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false
        },
        // 确认修改
        async changePwdYes() {
            let param = this.param

            // let msg = this.validate(param);
            // if (msg) {
            //     uni.$u.toast(msg)
            //     return;
            // }
            console.log(param);
            if (param.phone == "") {
                this.$message({
                    message: '请输入手机号',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (param.code == "") {
                this.$message({
                    message: '请输入验证码',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let a = this.checkPassword(param.newPwd)
            let b = this.checkPassword(param.confirmNewPwd)
            if (!a) {
                this.$message({
                    message: '密码格式不正确',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            if (!b) {
                this.$message({
                    message: '密码格式不正确',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            if (param.newPwd != param.confirmNewPwd) {

                this.$message({
                    message: '密码不一致，请重新输入',
                    type: 'warning',
                    offset: 100
                });
                return
            }
            let data = await this.$api.base.changePwd(param)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: '修改成功',
                    type: 'success',
                    offset: 100
                });
                this.param.phone = ''
                this.param.code = ''
                this.param.newPwd = ''
                this.param.confirmNewPwd = ''
                this.changePwd = false
                this.getUser()
                // uni.$u.toast('修改成功')
                // setTimeout(() => {
                //     // this.$util.navTo('pages/tabbar/home')
                //     uni.navigateBack({
                //         delta: 1
                //     })
                // }, 1000)
            }
            if (data.code == -9999) {
                this.$message({
                    message: data.msg,
                    type: 'error',
                    offset: 100
                });
            }
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false
        },
        // 获取用户数据
        async getUser() {
            let user = await this.$api.base.getUser()
            this.userinfo = user.info
            this.paramName.nickName = this.userinfo.nickName
            // console.log(this.userinfo);
        },
        handleClose() {
            this.dis = false
            this.show = true
            this.isGrey = false
            this.tixing = false
            clearInterval(this.timer)
            this.timer = null
            this.changePwd = false
            this.changeTel = false
            this.bindTel = false
            this.changeWx = false
        },
        changeNo() {
            this.tixing = false
            // location.reload();
            this.getUser()
            this.disabled = true
        },
        changeYes() {
            this.disabled = true
            this.tixing = false
            this.editData()
        },
        async editData() {
            let data = await this.$api.base.editData(this.paramName)
            console.log(data);
            if (data == null) {
                this.$message({
                    message: '修改成功，等待审核中',
                    type: 'success',
                    offset: 100
                });
                this.getUser()
                return
            }
            if (data.code == -9999 || data.code == -1) {
                this.$message({
                    message: data.msg,
                    type: 'warning',
                    offset: 100
                });
                return
            }


        },
        changePwdNo() {
            this.changePwd = false
            clearInterval(this.timer)

            this.dis = false
            this.show = true
            this.isGrey = false
        },
        async getCode(type) {
            console.log(type);
            let phone = ''
            if (type == 'pwd') {
                if (this.param.phone == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.param.phone

            }
            if (type == 'tel') {
                if (this.paramTel.phone == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.paramTel.phone
            }
            if (type == 'tel1') {
                if (this.paramTel.phoneNew == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.paramTel.phoneNew
            }
            if (type == 'tel11') {
                if (this.paramBindTel.phoneNew == "") {
                    this.$message({
                        message: '请输入手机号',
                        type: 'warning',
                        offset: 100
                    });
                    return
                }
                phone = this.paramBindTel.phoneNew
            }
            console.log(phone);
            if (phone.length != 11) {
                this.$message({
                    message: '手机号必须是11位',
                    type: 'warning',
                    offset: 100
                });
                return
            }

            let TIME_COUNT = 59;
            console.log(this.timer);
            if (!this.timer) {
                this.count = TIME_COUNT;
                this.isGrey = true;
                this.show = false;
                this.dis = true;
                let data = await this.$api.base.sendCode({
                    phone
                })
                console.log(data);
                if (data.code != -9999) {
                    this.$message({
                        message: "发送成功",
                        type: 'success',
                        offset: 100
                    });
                } else {
                    this.$message({
                        message: "发送失败",
                        type: 'error',
                        offset: 100
                    });
                }

                this.timer = setInterval(() => {
                    if (this.count > 1 && this.count <= TIME_COUNT) {
                        this.count--;
                    } else {
                        this.dis = false;
                        this.isGrey = false;
                        this.show = true;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },


    },
};
</script>
<style scoped lang="less">
/deep/ .el-dialog {

    border-radius: 10px !important;
}

/deep/ .el-dialog__headerbtn .el-dialog__close:hover {
    color: #909399;
}

input {
    color: #333333;
    background: #FFFFFF;
    opacity: 1;
    -webkit-text-fill-color: #999999;

    // [disabled] {
    //     background-color: red !important;
    // }



}

.main {
    .nickname {
        border: none;
    }

    input:focus {
        outline: none;
    }

    width: 100%;
    padding-top: 20px;

    .top_header {
        display: flex;
        align-items: center;

        .head {
            margin-right: 30px;

            img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                overflow: hidden;
            }
        }

        .user {
            // background-color: #00B079;
            height: 80px;
            // padding: 10px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .nickname {

                display: flex;
                align-items: center;
                font-size: 18px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 21px;


                .user_ico {
                    margin-left: 10px;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }

            .renzheng {
                padding: 3px 30px;
                width: 60px;
                background: #00B079;
                border-radius: 20px 20px 20px 20px;

                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
                // margin: 10px auto;
                margin-top: 30px;

            }
        }
    }

    .forms {
        margin-top: 100px;

        ul {
            li {
                border-bottom: 1px solid #CCCCCC;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0 37px 0;
                margin-bottom: 30px;

                .li_left {
                    display: flex;
                    align-items: center;

                    .li_ico {
                        margin-right: 10px;

                        img {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    .li_title {

                        font-size: 18px;
                        font-family: PingFang SC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        line-height: 21px;
                    }

                    .line {
                        width: 0px;
                        height: 20px;
                        opacity: 0.5;
                        border: 1px solid #CCCCCC;
                        margin: 0 50px;
                    }

                    .li_tip {
                        font-size: 16px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 19px;
                    }
                }

                .li_right {
                    .li_btn {
                        padding: 10px 50px;
                        border-radius: 10px 10px 10px 10px;
                        border: 1px solid #00B079;
                        font-size: 14px;
                        font-family: PingFang SC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #00B079;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .form {
        padding: 0 50px;

        .stepList {
            margin-bottom: 50px;

            .step {
                display: flex;
                justify-content: center;

                img {
                    width: 33%;
                }
            }
        }

        .tishi_con {
            font-size: 20px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 23px;
            text-align: center;
        }

        .ewm_img {
            display: flex;
            justify-content: center;
            margin: 0px 0 0px 0;
            flex-wrap: wrap;


        }

        .tip {
            display: flex;
            align-items: center;
            // padding-bottom: 100px;
            justify-content: center;
            flex-wrap: wrap;

            .tip_ico {

                margin-right: 10px;

                img {
                    width: 36px;
                    height: 36px;
                }
            }

            .tip_con {
                .every_con {

                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #CCCCCC;
                    line-height: 16px;

                }
            }
        }


        .tel {
            padding: 0 60px;

            input {
                width: calc(100% - 40px);
                height: 48px;
                background: rgb(240, 240, 240);
                border-radius: 10px 10px 10px 10px;
                padding: 10px 20px;
                border: none;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;



            }

            input:focus {
                outline: none;
            }


            margin-bottom: 30px;
        }

        .code {
            margin-bottom: 30px;
            position: relative;
            padding: 0 60px;

            input {
                width: calc(100% - 40px);
                height: 48px;
                background: rgb(240, 240, 240);
                border-radius: 10px 10px 10px 10px;
                padding: 10px 20px;
                border: none;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;

            }

            input:focus {
                outline: none;
            }

            button {
                position: absolute;
                right: 80px;
                top: 22px;
                border: none;
                font-size: 16px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
        }

        .title {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 19px;
            margin-bottom: 50px;
        }

        .agree {

            font-size: 12px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;

            .imgs {
                margin-right: 4px;
            }

            img {
                width: 18px;
                height: 18px;
            }

            span {
                font-size: 12px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #00B079;
                cursor: pointer;
            }
        }

        .login {
            margin: 11px 0;

            button {
                width: 100%;
                height: 48px;
                background: #00B079;
                border-radius: 4px 4px 4px 4px;
                border: none;
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #FFFFFF;
            }
        }

        .other {
            display: flex;
            justify-content: center;
            padding: 10px 0 0 0;
            flex-wrap: wrap;


            .no,
            .yes {
                border-radius: 4px 4px 4px 4px;
                padding: 10px 30px;
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                cursor: pointer;

            }

            .btn {
                // width: 75%;
                width: 340px;
                text-align: center;
                padding: 10px 30px;
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                cursor: pointer;
                color: #FFFFFF;
                background-color: #00B079;
                border-radius: 10px;
            }

            .btns {
                width: 150px;
                text-align: center;
                padding: 10px 30px;
                font-size: 20px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                cursor: pointer;
                color: #FFFFFF;
                background-color: #00B079;
                border-radius: 10px;
            }

            .no {
                color: #333333;
                margin-right: 30px;
            }

            .yes {
                color: #FFFFFF;
                background-color: #00B079;
            }
        }

        // .loginByWx {
        //     background-color: #00B079;
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     flex-wrap: wrap;
        //     font-size: 20px;
        //     font-family: PingFang SC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: #FFFFFF;
        //     padding: 10px 0;
        //     cursor: pointer;

        //     img {
        //         width: 24px;
        //         height: 24px;
        //     }
        // }
    }

}
</style>
